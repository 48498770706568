import * as React from 'react'
import { Layout } from '../components/layout'
import { PageHeader } from '../components/pageHeader'
import { DeviceOffline } from '../components/deviceOffline'

const PrinterOfflinePage = () => {
  return (
    <Layout>
      <PageHeader>3D Printer offline</PageHeader>
      <div>
        <DeviceOffline>
          Der 3D Drucker ist nicht erreichbar. Höchstwahrscheinlich weil er
          nicht angeschaltet ist. Am besten mal runter in die E-Werkstatt gehen.
        </DeviceOffline>
      </div>
    </Layout>
  )
}

export default PrinterOfflinePage
